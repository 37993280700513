<template>
  <div class="row text-center">
    <div class="col-lg-2 col-md-3 col-12">
      <router-link
        v-if="prev"
        @click.native="reloadLoc"
        :to="{ name: 'kamenDetail', params: { kamenSlug: prev.slug } }"
      >
        &#8592; {{ prev.name }}
      </router-link>
    </div>
    <div class="col-12 col-lg-2 offset-lg-8 col-md-3 offset-md-6">
      <router-link
        v-if="next"
        @click.native="reloadLoc"
        :to="{ name: 'kamenDetail', params: { kamenSlug: next.slug } }"
      >
        {{ next.name }} &#8594;
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    kategoria: Object,
    kamen: Object,
  },
  data() {
    return {
      next: null,
      prev: null,
    };
  },
  mounted() {
    this.setNextPrev();
  },
  methods: {
    reloadLoc() {
      location.reload();
    },
    setNextPrev() {
      if (this.kategoria != null && this.kategoria.items != null) {
        for (var i = 0; i < this.kategoria.items.length; i++) {
          if (this.kategoria.items[i].slug == this.kamen.slug) {
            var prevIndex = i - 1;
            var nextIndex = i + 1;

            if (prevIndex < 0)
              prevIndex = prevIndex + this.kategoria.items.length;
            if (nextIndex == this.kategoria.items.length)
              nextIndex = nextIndex - this.kategoria.items.length;

            this.prev = this.kategoria.items[prevIndex];
            this.next = this.kategoria.items[nextIndex];
          }
        }
      }
    },
  },
};
</script>