<template>
  <table class="table">
    <thead>
      <tr>
        <th scope="col" v-for="th in table.header" :key="th">
            {{th}}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="tr in table.body" :key="tr">
        <td v-for="td in tr" :key="td">{{td}}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    table: Object,
  },
};
</script>