<template>
    <div class="row mb-5" v-if="kamenInfo">
        <div class="col-sm-6 col-md-6 mb-4 mb-lg-0 col-lg-4" v-for="i in kamenInfo" :key="i.title">
          <strong class="d-block text-black">{{i.title}}</strong>
          {{i.value}}
        </div>
      </div>
</template>
<script>
export default {
    name:"kamen-info",
    props: {
        'kamenInfo': Array
    }
}
</script>