<template>
  <section class="site-section portfolio-single" id="next-section">
    <div class="container">
      <div class="row mb-3">
        <div class="col-12 text-center" data-aos="fade">
          <h2 class="section-title mb-3">{{ title }}</h2>
        </div>
      </div>
      <Breadcrumb :pages="breadcrumb" v-if="breadcrumb != null" />
      <KamenInfo :kamenInfo="kamenDetail.info" v-if="kamenDetail && kamenDetail.info" />
      <KamenCena :kamenCena="kamenDetail.cena" v-if="kamenDetail && kamenDetail.cena" />
      <KamenTable :table="kamenDetail.table" v-if="kamenDetail && kamenDetail.table" />
      <KamenGaleria :images="kamenDetail.images" v-if="kamenDetail && kamenDetail.images" />
      <CategoryNav :kamen="kamenDetail" :kategoria="kategoria" />
    </div>
  </section>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import KamenCena from "@/components/KamenCena.vue";
import KamenInfo from "@/components/KamenInfo.vue";
import KamenGaleria from "@/components/KamenGaleria.vue";
import CategoryNav from "@/components/CategoryNav.vue";
import KamenTable from "@/components/KamenTable.vue";
export default {
  name: "kamenDetail",
  components: {
    Breadcrumb,
    KamenCena,
    KamenInfo,
    KamenGaleria,
    CategoryNav,
    KamenTable
  },
  metaInfo() {
    return {
      title: this.metaTagy.title,
      meta : [
        { name:"title", content: this.metaTagy.title },
        { name:"description", content: this.metaTagy.description },
        { name:"keywords", content: this.metaTagy.keywords },
        { property:"og:type", content:"website" },
        { property:"og:url", content:"https://predajkamena.sk" + this.metaTagy.urlPath },
        { property:"og:title", content: this.metaTagy.title },
        { property:"og:description", content: this.metaTagy.description },
        { property:"og:image", content:"http://predajkamena.sk" + this.metaTagy.img },
        { property:"twitter:card", content:"summary_large_image" },
        { property:"twitter:url", content:"https://predajkamena.sk" + this.metaTagy.urlPath },
        { property:"twitter:title", content: this.metaTagy.title },
        { property:"twitter:description", content: this.metaTagy.description },
        { property:"twitter:image", content:"http://predajkamena.sk" + this.metaTagy.img },
      ]
    }
  },
  data() {
    return {
      metaTagy: {
        title: this.title + " | Predaj prírodného kameňa",
        keywords: this.title + ", produkt, sortiment, ponuka kamena, andrej dula, kamen, predaj kamena, prirodny kamen, predaj prirodneho kamena, banska bystrica",
        description: this.title + " je jeden z mnohých kameňov v ponuke v kategórii " ,
        urlPath: this.$router.fullpath,
        img: ""
      },
      ponukaKamena: null,
      breadcrumb: null,
      kamenDetail: null,
      title: null,
      kategoria: null,
    };
  },
  async mounted() {
    this.$axios
      .get("/data/ponuka-kamena.json")
      .then((response) => this.setData(response.data))
      .catch(function (error) {
        console.log(error);
      });
  },
  methods: {
    setData(data) {
      this.ponukaKamena = data;
      this.setItem();
      this.setTitle();
      this.setMetaTagy();
    },
    setTitle() {
      if (this.kamenDetail != null) {
        this.title = this.kamenDetail.name;
      }
    },
    setMetaTagy () {
      this.metaTagy = {
        title: this.title + " | Predaj prírodného kameňa",
        keywords: this.title + ", produkt, sortiment, ponuka kamena, andrej dula, kamen, predaj kamena, prirodny kamen, predaj prirodneho kamena, banska bystrica",
        description: this.title + " je jeden z mnohých kameňov v kategórii " + this.kategoria.title + " ktoré sú v našej ponuke.",
        urlPath: this.$router.fullpath,
        img: this.getDefaultImage(),
      };
    },
    async getDataFromFile (file) {
      var fileData;
      this.$axios
        .get(file)
        .then((response) => {
          fileData = response.data;
        })
        .catch(function (error) {
          console.error(error);
        });
      return fileData;
    },
    getShortCatSlugFromImageSlug (imageSlug) {
      return imageSlug.split("-")[0];
    },
    getDefaultImage () {
      var defImg = "";
      if (this.kamenDetail != null) {
        this.kamenDetail.images.forEach((img) => {
          if (img.default == true) {
            defImg = img.thumb;
          }
        });
      }
      return defImg;
    },
    setItem() {
      this.breadcrumb = [];
      this.breadcrumb.push({ href: "/ponuka-kamena", icon: "icon-home" });
      this.ponukaKamena.forEach((kategoria) => {
        if (kategoria.subcategories !== undefined) {
          kategoria.subcategories.forEach((subcat) => {
            if (subcat.items !== undefined) {
              subcat.items.forEach((item) => {
                if (item.slug == this.$route.params.kamenSlug) {
                  this.kamenDetail = item;
                  this.kategoria = subcat;
                  this.breadcrumb.push({
                    title: kategoria.title,
                    href: "/ponuka-kamena/" + kategoria.slug,
                  });
                  this.breadcrumb.push({
                    title: subcat.title,
                    href:
                      "/ponuka-kamena/" + kategoria.slug + "/" + subcat.slug,
                  });
                  this.breadcrumb.push({
                    title: item.name,
                    href: "/kamen/" + item.slug,
                  });
                }
              });
            }
          });
        } else if (kategoria.items !== undefined || kategoria.itemsJson !== undefined) {
          kategoria.items.forEach((item) => {
            if (item.slug == this.$route.params.kamenSlug) {
              this.kamenDetail = item;
              this.kategoria = kategoria;
              this.breadcrumb.push({
                title: kategoria.title,
                href: "/ponuka-kamena/" + kategoria.slug,
              });
              this.breadcrumb.push({
                title: item.name,
                href: "/kamen/" + item.slug,
              });
            }
          });
        }
      });
    },
  },
};
</script>