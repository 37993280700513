<template>
    <div class="row mb-5" v-if="kamenCena">
        <div class="col-md-12 text-right" :class="{'oldPrice' : kamenCena.akciova_cena}">
          <strong :class="{'text-black' : !kamenCena.akciova_cena}">Cena:</strong>
          {{kamenCena.cena}} &euro; {{kamenCena.jednotka}}
        </div>
        <div class="col-md-12 text-right salePrice" v-if="kamenCena.akciova_cena">
          <strong>Akcia:</strong>
          {{kamenCena.akciova_cena}} &euro; {{kamenCena.jednotka}}<br />
          <span class="percentageDiscount">Zľava: ~ {{percentageDiscount}} %</span>
        </div>
      </div>
</template>
<script>
export default {
    name:"kamen-cena",
    props: {
        'kamenCena': Object
    },
    computed: {
        percentageDiscount : function () {
            if (this.kamenCena.akciova_cena) {
                return Math.round(100 - 100 / this.kamenCena.cena * this.kamenCena.akciova_cena)
            }
            return 0;
        }
    }
}
</script>
<style lang="scss">
.oldPrice {
    text-decoration: line-through;
    color: #666;
}
.salePrice {
    font-weight: bold;
    color: #c00;
    font-size: 1.5rem;
    line-height: 1;
    .percentageDiscount {
        font-size: 1rem;
    }
}
</style>