<template>
    <div class="row no-gutters" v-if="images">
          <div class="col-md-6 col-lg-4 mb-4 item text-center" v-for="image in images" :key="image.full">
            <a :href="image.full" class="item-wrap fancybox" data-fancybox="gallery2">
              <span class="icon-search-plus"></span>
              <img class="img-fluid" :src="image.thumb">
            </a>
          </div>
    </div>
</template>

<script>
export default {
    name:"kamen-galeria",
    props: {
        images: Array
    }
}
</script>