<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item" v-for="item in pages" :key="item.title">
        
        <a :href="item.href" v-if="!isLastItem(pages, item)">
          <i :class="item.icon" aria-hidden="true" v-if="item.icon"></i>
          {{ item.title }}
        </a>
        <span v-else>
          <i :class="item.icon" aria-hidden="true" v-if="item.icon"></i>
          {{ item.title }}
        </span>
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
    props: {
        'pages': Array
    },
  data() {
    return {
    };
  },
  methods: {
      isLastItem (array, item) {
          if (array.length == 0) return false;
          return array[array.length - 1] == item;
      }
  }
};
</script>